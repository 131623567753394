import React, { lazy, Suspense } from "react";
import { useNavigate } from 'react-router-dom';
import styles from "./cards.module.css";
import { StarIcon } from "../../../images";

// Lazy load the Button component
const Button = lazy(() => import("../../button"));

const PaymentCard = ({ card, setIsEnrolOpen }) => {
  const navigate = useNavigate();
   const handleButtonClick = () => {
    if (card.type.trim() === 'Free') {
      setIsEnrolOpen(true);
    } else {
      window.open(card.buttonUrl, "_blank");
    }
  };

  return (
    <div
      className={
        card.type === "Recommended"
          ? `${styles["card_container"]} ${styles["secondary"]}`
          : styles["card_container"]
      }
    >
      {card.type === "Recommended" && (
        <div className={styles["pill"]}>
          <img src={StarIcon} alt="star icon" loading="lazy" width="32" height="32" />
          <p className={styles["pill_text"]}>{card.pillText}</p>
        </div>
      )}
      <p className={styles["card_title"]}>{card.type}</p>
      <div className={styles["card_currency_wrapper"]}>
        <span className={styles["card_currency"]}>{card.symbol}</span>
        <p className={styles["card_amount"]}>{card.price}</p>
        {card.slashPrice && parseInt(card.slashPrice) > 0 && (
          <p className={styles["price_slash"]}>
            <span className={styles["price_slash_currency"]}>{card.symbol} </span>
            <span>{card.slashPrice}</span>
            <div className={styles["price_slash_line"]}></div>
          </p>
        )}
      </div>
      <p className={styles["card_content"]}>{card.description}</p>
      <Suspense fallback={<div>Loading...</div>}>
        <Button
          className={styles["card_button"]}
          variant={card.type === "Recommended" ? "secondary" : "primary"}
          onClick={handleButtonClick}
        >
          {card.buttonLabel}
        </Button>
      </Suspense>
    </div>
  );
};

export default PaymentCard;