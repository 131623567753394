import { 
  Card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7, 
  card8
} from "../images";

export const BLOG_CARD_DATA = [
  {
    category: "Self Development",
    image: Card1, 
    author: "Anthony Chow",
    date: "July 25, 2022",
    readTime: "5 min to read",
    title: "Business Analysis Bootcamp Business",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Business Analysis Bootcamp Business")}`
  },
  {
    category: "Design",
    image: card2, 
    author: "Isabel Cokely",
    date: "July 28, 2022",
    readTime: "3 min to read",
    title: "Design Thinking Bootcamp",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Design Thinking Bootcamp")}`
  },
  {
    category: "Business",
    image: card3, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Business Analysis Bootcamp",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Business Analysis Bootcamp")}`
  },
  {
    category: "Management",
    image: card4, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Management Bootcamp",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Management Bootcamp")}`
  },
  {
    category: "Self Development",
    image: card5, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Self Development Bootcamp",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Self Development Bootcamp")}`
  },
  {
    category: "Business",
    image: card6, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Advanced Business Analysis",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Advanced Business Analysis")}`
  },
  {
    category: "Business",
    image: card7, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Leadership and Business Strategy",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Leadership and Business Strategy")}`
  },
  {
    category: "Business",
    image: card8, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Global Business Strategy",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  },
  {
    category: "Design",
    image: card7, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Global Business Strategy",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  },
  {
    category: "Self Development",
    image: card6, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Global Business Strategy",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  },
  {
    category: "Business",
    image: card5, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Global Business Strategy",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  },
  {
    category: "Business",
    image: card3, 
    author: "Michael Alphon",
    date: "July 30, 2022",
    readTime: "6 min to read",
    title: "Global Business Strategy",
    description: "Course description goes here description here Course descript here description here Course...",
    link: `/resources/blog/${encodeURIComponent("Global Business Strategy")}`
  }
];


