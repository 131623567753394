export const ARTICLE_DATA = [
    {
        header: "",
        paragraphs: [
            `Doing A Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the Personal Information Protection and Electronic Documents Act (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,
            `Thing Inc is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,
            `Strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act`,
            `(PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,
            <>
                Doing A Thing Inc.
                <span>   </span>
                <span class="bg-customYellow">
                    (“Co.Lab”, "we," "our," and/or "us" is strongly committed to protecting your privacy and complying with the Personal Information Protection and Electronic Documents Act)
                </span>
                <br />
                (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.
            </>,

            <>
                <div class="border-l-4 border-primaryYellow pl-6">
                    `“ Strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
                    (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations. “`
                </div>
            </>,

            `Doing A Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`
        ],


    },

    {
        header: "Business Analysis Bootcamp Business Analysis",
        paragraphs: [
            ` Doing A Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,
            `Strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            <>
                <h3>Business Analysis Bootcamp Business Analysis</h3>
            </>,

            `Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            <>
                <ul className="bullet__point">
                    <li>
                        Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act.

                        <ul className="bullet__subpoint">
                            <li>
                                Thing Inc.
                            </li>
                            <li>
                                Strongly committed to protecting your privacy.
                            </li>
                            <li>
                                Electronic Documents Act.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act.
                    </li>
                    <li>
                        Committed to protecting your privacy.
                    </li>
                </ul>
            </>,

            `Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            <>
                <h3>Business Analysis Bootcamp Business Analysis</h3>
            </>,

            `Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`
        ],

    },

    {
        header: "Business Analysis Bootcamp Business Analysis",
        paragraphs: [
            `Doing A Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            `Thing Inc is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            `Strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            `Doing A Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,

            <>
                <div class="border-l-4 border-primaryYellow pl-6">
                    “ Strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
                    (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations. “
                </div>
            </>,

            `Doing A Thing Inc. (“Co.Lab”, "we," "our," and/or "us") is strongly committed to protecting your privacy and complying with the *Personal Information Protection and Electronic Documents Act
            (PIPEDA), General Data Protection Regulation (GDPR), California Consumer Privacy Act (CCPA), and other applicable rules, laws, and regulations.`,
        ],
    }
];

