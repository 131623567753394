import { MatchDownIcon, MatchUpIcon } from "../images";

export const FAST_TRACK_SCHEDULE = [
  {
    id: 1,
    session: "Session 1",
    sessionTime: "Friday, 11 October 2024",
    sessionTitle: "Course Schedule",
    duration: "October 11th, 2024 7 pm - 10pm (EST)",
    time: "Time: 11 AM - 2 PM EST",
    description: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItems: [
      {points: "Introduction to Business Analysis"},
      {points: "The Business Analysis Job Functions"},
      {points: "Core Competencies of a Business Analyst"},
      {points: "Software Development Life Cycle (SDLC)"},
      {points: "Waterfall and Agile"},
    ],
  },
  {
    id: 2,
    session: "Session 2",
    sessionTime: "Saturday, 12 October 2024",
    sessionTitle: "Course Schedule",
    duration: "October 12th, 2022 4 pm - 7pm (EST)",
    time: "Time: 4 PM - 7 PM EST",
    description: "Launch your data analytics journey with an introduction to data by learning foundational concepts and new skills for approaching data problems. You'll start your learning journey by developing a problem-solving framework and by understanding how data should be collected and prepared for data analysis. Translate your learnings into job-ready skills by solving real-world problems with a variety of data sets.",
    pointItems: [
      {points: "Requirements Life Cycle Management "},
      {points: "Requirements Elicitation"},
      {points: "Epics, User Stories and Acceptance Criteria"},
      {points: "Question and Answer Session"},
      {points: "Next Steps Guide"},
    ],
  }
]; 
FAST_TRACK_SCHEDULE.map((week, index) => ({
  ...week,
  icon: index % 2 === 0 ? MatchDownIcon : MatchUpIcon,
}));