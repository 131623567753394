import React from 'react';
import styles from './satisfaction.module.css';
import { star } from '../../images';
import Button from '../button';

const Satisfaction = ({satisfaction}) => {
  return (
    <section className={styles['section']}>
      <div className={styles['block']}>
        {satisfaction ? <img src={star} alt='star' loading='lazy'/> : <div className={styles["tag_black"]}>It's FREE!</div>}
        {satisfaction ? <div className={styles['text']}>"100% satisfaction gurantee"</div> :
        <>
        {/* Display heading for Mobile */}
        <h2 className= 'font-bold text-[#ffffff] text-xl text-center md:hidden'>This course will help you launch your Business Analysis career in the shortest possible time</h2>
         {/* Display heading for desktop */}
        <div className='hidden font-bold text-[#ffffff] text-center md:flex md:flex-col md:text-[28px] md:w-[790px]'>
          <p>This free MasterClass will help you launch and accelerate your </p>
          <p>business analysis career in the shortest possible time.</p>
        </div>
        </>}
       
        <span className={styles['span']}>{satisfaction ? "4.7 out of 5.0 stars" :
        "Be Agile about your Business Analysis transition"}</span>
        
        <Button className={styles['button']} icon="blackArrow">
          Register Now
        </Button>
      </div>
    </section>
  );
};
export default Satisfaction;
