import {
  bagIcon,
  balsamiqIcon,
  bookIcon,
  confluenceIcon,
  contactIcon,
  drawIcon,
  freeIcon,
  interviewIcon,
  jiraIcon,
  ledIcon,
  miroIcon,
  mysqlIcon,
  playIcon,
  sqlIcon,
  suitcaseIcon,
  trelloIcon,
  userIcon,
  vIcon,
  visio,
  workIcon,
  writeIcon,
  fastTrackInstruct,
  figmaLogo,
  sql,
  github,
  powerBI,
  excel
} from "../images";

export const CARD_FIRST_COLUMN = [
  {
    firstColumn: [
      {
        icon: workIcon,
        title: "For a complete novice",
        text: "Join our beginner-friendly program and start your journey with confidence. No experience needed – get started today!",
      },
      {
        icon: freeIcon,
        title: "Start Free",
        text: "Try out our first week for free when you register.  You will get exclusive access to the week 1 live sessions.",
      },
      {
        icon: writeIcon,
        title: "On-the-Job Support",
        text: "Never walk alone. Eliminate impostor syndrome on the job. Our tutors and alumni will work with you to deliver excellence on the job.",
      },
    ],
  },
  {
    secondColumn: [
      {
        icon: bagIcon,
        title: "Internship",
        text: "Get real world experience with our internship program. Work on unique client projects across different verticals to boost your resume and job search.",
      },
      {
        icon: sqlIcon,
        title: "Personalized Career Coaching",
        text: "Get access to 1-on-1 resume clinic and interview prep to stand out from the crowd and distinguish yourself with potential employers.",
      },
      {
        icon: userIcon,
        title: "BONUS: Scrum Master & Product Owner Training",
        text: "Diversify your skillset and Elevate your career with bonus modules on scrum and product ownership taught by our expert instructors.",
      },
    ],
  },
];

export const CARD_SECOND_COLUMN = [
  {
    firstColumn: [
      {
        icon: workIcon,
        title: "Beginner Friendly",
        text: "Start your journey with confidence. No experience needed – get started today!"
      },
      {
        icon: freeIcon,
        title: "Free one-week trial",
        text: "Enjoy the first week free with exclusive access to live sessions."
      },
      {
        icon: fastTrackInstruct,
        title: "Expert-Led Live Training",
        text: "Learn from leaders who have managed products at Meta, Google, and more."
      },
    ],
  },
  {
    secondColumn: [
      {
        icon: interviewIcon,
        title: "Hands-on Projects",
        text: "Gain practical experience with real-world projects and case studies."
      },
      {
        icon: suitcaseIcon,
        title: "Job-ready Skills",
        text: "Acquire the skills needed to excel in product management roles."
      },
      {
        icon: playIcon,
        title: "Networking Opportunities",
        text: "Connect with industry professionals and fellow learners to expand your network."
      },
    ],
  },
];

export const COURSE_TOOLS = [jiraIcon, confluenceIcon, miroIcon, figmaLogo]
export const COURSE_TOOLS2 = [sql, excel, powerBI, github]
export const COURSE_TOOLS1 = [jiraIcon, confluenceIcon, balsamiqIcon, miroIcon, figmaLogo, drawIcon, visio, trelloIcon]

