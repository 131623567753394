import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useOnHoverOutside } from "../../../hooks/useOnHoverOutside";
import styles from "./dropdown.module.css";
import { arrowRightWhiteIcon, cancelWhiteIcon, arrowDownWhite } from "../../../images";
import { renderValidUrl } from "../../../utils/constants";

const MenuElement = ({ item, setDropdown, width, handleMenu }) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(width < 992 ? false : true)

  const handleExpand = (e) => {
    if (width < 992) {
      setExpanded(!expanded);
    }
  }

  const handleClick = (url) => {
    setDropdown(false);
    if (width < 992) {
      handleMenu()
    }
    window.location.href = url;
  }

  return (
    <div className={styles["list_el"]}>
      <h1 onClick={(e) => handleExpand(e)} >
        <span>
          <img src={renderValidUrl(item.icon)} alt="menu icon" loading="lazy" />
          <span>{item.title}</span>
        </span>
        {
          width < 992 &&
          <img
            src={arrowDownWhite}
            alt="arrow down"
            loading="lazy"
            className={expanded && styles["arrow_down"]}
          />
        }
      </h1>
      {
        expanded &&
        <p>
          {item.courseDescription}
        </p>
      }
      {
        expanded &&
        <div>
           <span
              onClick={() => handleClick(`/courses/${item.slug}`)}
              className={styles["link"]}
            >
              {`Bootcamp →`}
            </span>

            {/* Conditionally render the FastTrack link */}
            {item.slug.includes("business-analysis") && (
            <span
              onClick={() => handleClick(`/free-course`)}
              className={styles["link"]}
            >
              {`Free Crash Course →`}
            </span>
          )}

          {
            item.links?.map((link, index) => (
              <span
                key={index}
                onClick={() => handleClick(link.url)}
                className={styles["link"]}
              >
                {`${link.label} →`}
              </span>
            ))
          }
        </div>
      }
    </div>
  )
}


const Dropdown = ({ show, menu, handleMenu, setDropdown }) => {
  const navigate = useNavigate()
  const handleDropdown = () => setDropdown(false);
  const dropdownRef = useRef(null); // Create a reference for dropdown container

  const [width, setWidth] = useState(window.innerWidth)
  const handleResize = () => {
    setWidth(window.innerWidth)
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [limit, setLimit] = useState(0)
  useEffect(() => {
    setLimit(width < 1200 ? 6 : 8)
  }, [width])

  // Calculate the left position based on the number of menu items
  const dropdownBodyStyle = {
    left: menu.length === 1 ? "-200px" : "0",
  };

  useOnHoverOutside(dropdownRef, handleDropdown); // Call the hook

  return show && (
    <div
      className={styles["dropdown_body"]}
      ref={dropdownRef}
      onMouseOver={() => setDropdown(true)}
      onClick={(e) => e.stopPropagation()}
      style={dropdownBodyStyle} // Apply dynamic style
    >
      <div className={styles["positioning"]}>
        <div className={styles["hidden"]}>
          <button onClick={(e) => { setDropdown(false); e.stopPropagation() }}>
            <img src={arrowRightWhiteIcon} alt="nav-logo" />
          </button>
          <h1>For Students</h1>
          <img
            src={cancelWhiteIcon}
            alt="cancel white icon"
            loading="lazy"
            onClick={() => { handleMenu(); setDropdown(false); }}
            className={styles["arrow_icon"]}
          />
        </div>
        <div className={`${styles["list_body"]} ${menu.length > 3 && styles["long_list"]}`}>
          {
            menu.slice(0, menu.length > limit ? limit - 1 : menu.length).map((item, index) => (
              <MenuElement key={index} item={item} setDropdown={setDropdown} width={width} handleMenu={handleMenu} />
            ))
          }
          {
            menu.length > limit &&
            <span className={styles["see_all"]} onClick={() => { setDropdown(false); navigate('/all-course') }}>
              {'See all courses →'}
            </span>
          }
        </div>
      </div>
    </div>
  );
};

export default Dropdown;