import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TSGBg from "../tsg-bg";
import PaymentCard from "./cards";
import CourseBanner from "../banner/course-banner";
import ToggleSwitch from "./ToggleSwitch";

const Payments = React.forwardRef(({
  setIsEnrolOpen, isEnrolOpen, regionPrice, showBanner, courseBanner, setShowBanner
}, ref) => {
  const { formData } = useSelector((state) => state);
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false); // Track toggle state for currency switching
  const [paymentOptionsWithSymbols, setPaymentOptionsWithSymbols] = useState([]);

  useEffect(() => {
    if (formData.defaultRegion in regionPrice) {
      const userRegion = regionPrice[formData.defaultRegion];
      console.log("user region", userRegion, paymentOptionsWithSymbols);
      if (userRegion.cohorts && userRegion.payments) {
        const paymentOptions = userRegion.payments;
        let updatedPaymentOptions = [];

        // Adjust based on toggle state and region
        if (isFeatureEnabled) {
          if (formData.defaultRegion === "Europe - Africa") {
            // Toggle ON: Use the actual price in EUR
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.price || option.defaultPrice, // Default to price if available
              symbol: "€", // EUR symbol
            }));
          } else if (formData.defaultRegion === "US & Canada") {
            // Toggle ON: Use the actual CAD price
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.defaultPrice || option.price, // Display defaultPrice in CAD
              symbol: "c$", // CAD symbol
            }));
          }
        } else {
          if (formData.defaultRegion === "Europe - Africa") {
            // Toggle OFF: Use the default GBP price
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.defaultPrice || option.price, // Default price for GBP
              symbol: "£", // GBP symbol
            }));
          } else if (formData.defaultRegion === "US & Canada") {
            // Toggle OFF: Use the USD price
            updatedPaymentOptions = paymentOptions.map((option) => ({
              ...option,
              price: option.price || option.defaultPrice, // Display price in USD
              symbol: "$", // USD symbol
            }));
          }
        }

        setPaymentOptionsWithSymbols(updatedPaymentOptions);
      } else {
        console.log("Payment options or cohorts not found for the user's default region.");
      }
    }
  }, [isFeatureEnabled, regionPrice, formData.defaultRegion]);

  const handleToggle = (newState) => {
    setIsFeatureEnabled(newState); // Track toggle state for currency switching
  };

  return (
    <TSGBg bgStyle="slopeLeftLow">
      <div ref={ref} className="w-screen lg:w-[calc(100vw-24px)] xl:w-[1200px] flex flex-col items-center gap-10 lg:gap-[45px]">
        <h3 className="text-xl md:text-[28px] md:leading-9 text-[#1E1B2E] flex gap-4 font-bold flex-col items-center">
          Flexible and Affordable Pricing
          <ToggleSwitch
            onColor="#F8D210"
            offColor="#ccc"
            isOnInitial={isFeatureEnabled}
            onToggle={handleToggle}
            regionPrice={formData.defaultRegion} // Pass region to ToggleSwitch
          />
        </h3>
        {showBanner && (
          <CourseBanner
            showBanner={showBanner}
            setShowBanner={setShowBanner}
            {...courseBanner}
          />
        )}
        <div className="flex flex-col lg:flex-row w-full max:h-[1050px] h-full gap-4 lg:gap-16 justify-between">
         {paymentOptionsWithSymbols.map((priceOption) => (
          <PaymentCard
            key={priceOption.id}
            card={{
              ...priceOption, // Passing the entire updated price object
              price: priceOption.price, // Will be the price or defaultPrice depending on toggle
              symbol: priceOption.symbol, // Will be the appropriate currency symbol
              userRegion: regionPrice[formData.defaultRegion]
            }}
            setIsEnrolOpen={setIsEnrolOpen}
            isEnrolOpen={isEnrolOpen}
          />
        ))}
        </div>
      </div>
    </TSGBg>
  );
});

export default Payments;
